import { Component } from "preact";
import * as helpers from "@cargo/common/helpers";
import { withRouter } from 'react-router';
import withStore from '../withStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions"
import selectors from "../selectors";
import _ from 'lodash';
import { overlayMethods } from './overlay-controller';
import { validateShopModel } from './cart';

if(!helpers.isServer) {

	window.logSiteStructure = () => {

		const state = window.store.getState();
		const sortMap = state.structure.bySort;
		const idList = Object.keys(sortMap);

		idList.sort((a,b) => {
			return sortMap[a] - sortMap[b]
		});

		const tableData = {};

		idList.forEach(id => {

			const model = state.pages.byId[id] || state.sets.byId[id];

			//console.log('sort', state.structure.bySort[id], 'index', state.structure.indexById[id], 'title', model.title)

			tableData[id] = {
				title: model.title,
				sort: sortMap[id],
				index: state.structure.indexById[id],
				page_count: model.page_count
			}

		})

		console.table(tableData)

	}
	
}

class GlobalEventsHandler extends Component {

	constructor(props) {
		
		super(props);

		this.state = {};

		this.isMac = false;

		if(!helpers.isServer) {

			import('./quick-view').then(({openQuickViewFromElement}) => {
				this.setState({
					openQuickViewFromElement: openQuickViewFromElement
				})
			})

			this.isMac = helpers.isMac();
		}

	}

	handleGlobalClick = e => {

		let target = e.target;
		let originalTarget = target;

		// if we clicked on an element with a shadowroot, check for links inside the shadowroot
		// linked media items work by submerging their link inside the shadow dom
		if( target.shadowRoot ){
			target = e.composedPath().find(node=>node.tagName==='A') || target;
		} else if(target.closest('a')) {
			target = target.closest('a');
		}

		// let the overlay controller close the appropriate
		// overlays before we proceed
		overlayMethods.handleGlobalClick(target, originalTarget);

		// handle links with rel="history". They should navigate using JS.
		if(
			!e.defaultPrevented
			&& target 
			// SVG link nodeNames are lowercased
			&& (target.nodeName === "A" || target.nodeName === "a")
			&& target.getAttribute("href")
			&& target.getAttribute("href") !== ""
		) {
			
			let rel = target.getAttribute('rel');

			if ( this.props.adminMode) {
				e.preventDefault();
				return;
			}

			let href = target.getAttribute('href') || "";
			let linkTarget = target.getAttribute('target');
			let tags;

			switch( rel ) {
			
				case 'history' :
			
					e.preventDefault();
					
					const state = this.props.store.getState();

					// if the page is an overlay (part of scaffolding so overlay pages are always pre-loaded)
					const page = selectors.getContentByPurl(store.getState(), href.replace(/\//, ''));

					if(page?.overlay) {
						overlayMethods.toggleOverlay(page.id);

						// no routing for overlays
						return;
					}

					tags = target.getAttribute('data-tags'); 
					let preventScrollReset = false;

					if( tags?.length > 0 ){
						href = tags;
					} else {

						const homepagePurl = selectors.getHomepagePurl(state);

						// if we have a regular link that links to the homepage PURL navigate to the site root (/) instead
						if(
							href.toLowerCase() === homepagePurl
							// in feed mode "/" doesn't load the homepage but root, so always
							// directly link to the homepage PURL
							&& !state.sets.byId.root.stack
						) {
							href = "/"
						}

					}

					// only prepend slash if not already there
					if(!href.startsWith('/')) {
						href = '/' + href;
					}

					if( href === '/contact-form' ){
						preventScrollReset = true;
					}

					// scroll to top of window
					if (originalTarget.nodeName === 'MEDIA-ITEM' 
						&& originalTarget?.classList.contains('thumbnail')
						&& href.replace(/\//, '') === this.props.activePURL
					) {
						window.scrollTo(0,0);
					}

					if ( this.isMac && e.metaKey === true || !this.isMac && e.ctrlKey === true ) {
						window.open(href, '_blank');
					} else {
						this.props.history.push(href, {preventScrollReset: preventScrollReset})
					}
					
				break;
			
				case 'prev_page' :
				case 'prev-page' : 
					e.preventDefault();
			
					this.loadPrevPage();
			
				break;
			
				case 'next_page' :
				case 'next-page' : 
					e.preventDefault();

					this.loadNextPage();
			
				break;
			
				case 'home_page' :
				case 'home-page' : 

					e.preventDefault();
					this.props.history.push('/');
			
				break;
			
				case 'random_page':
				case 'random-page': 
			
					e.preventDefault();
					this.loadRandomPage();
			
				break;
			
				case 'quick-view':
				case 'quick_view':
			
					e.preventDefault();
					
					this.state.openQuickViewFromElement?.(e.target);

			
				break;

				case 'close-overlay':
				case 'close_overlay':
					
					e.preventDefault();

					const pid = originalTarget.closest('[page-url]')?.id;
					
					if(overlayMethods.getOverlay(pid)) {
						// close overlay that contained this link
						overlayMethods.closeOverlay(pid)
					} else {
						// go home
						this.props.history.push('/');
					}

				break;
			
				case 'add-to-cart':
				case 'add_to_cart':
					
					e.preventDefault();

					this.props.addCommerceProductToCart(
						(target.getAttribute('data-product') || e.target.getAttribute('data-product')) ?? null, 
						(target.getAttribute('data-variant') || e.target.getAttribute('data-variant')) ?? null,
						{
							callback: ()=>{
								this.props.history.push('/cart', {preventScrollReset: true});
							},
							failureCallback: (message)=>{
								this.props.updateFrontendState({
									alertModal: {
										header: message,
										type: 'notice'
									}
								});
							}
						}
					);


				break;
			
				case 'show_cart' :
				case 'show-cart' : 
				
					e.preventDefault();

					validateShopModel().then(shopModel => {
						this.props.history.push('/cart', {preventScrollReset: true});
					}).catch(msg => {
						console.warn(msg);
					});

				break;

				case 'filter-index':
				case 'filter_index':
					

					e.preventDefault();
					tags = target.getAttribute('data-tags');
					let set = target.getAttribute('data-set');

					var event = new CustomEvent('filter-thumbnail-index', {
						bubbles: true,
						cancelable: true,
						composed: true,
						detail: {
							filter: tags ? 'tag:'+ tags : set ? 'set:'+set : 'all'
						}
					})
					target.dispatchEvent(event);
				break;
			
				default:
					
					// Site preview's iframe restrictions make it impossible to reliably load direct links. Always open in new tab on the parent
					if(this.props.isInHomepagePreview) {

						// don't follow the link inside the frame
						e.preventDefault();
						// make the parent open the href so we send a valid origin
						parent.window.open(href);

					} else if( linkTarget !== '_blank' ){

						// don't follow the link inside the frame
						e.preventDefault();
						// Navigate the parent admin frame to the new location
						parent.location.href = href;

					}

				break;
			}
	
		}

	}

	openAdmin = () => {
		// Do not run when not in admin and still in an iframe. For now
		// this is mostly to prevent templates being previewed from opening their admin
		if(window.parent !== window){
			return;
		}

		let pidToEdit = this.props.activePID;

		if(this.props.store.getState().sets.byId[pidToEdit] !== undefined) {
			// active PID is a set. Load the first rendered page if possible
			const firstRenderedPage = document.querySelector('.content .pages .page');

			if(firstRenderedPage) {
				pidToEdit = firstRenderedPage.id;
			} else {
				pidToEdit = '';
			}

		}

		window.location.href = '/edit/' + pidToEdit;

	}

	showCargoSiteIndicator = () => {

		const img = new Image();

		// preload image, then start the show
		img.onload = function(){
			
			const div = document.createElement('div');

			div.style.width = '100%';
			div.style.height = '100%';
			div.style.zIndex = '2147483647';
			div.style.position = 'fixed';
			div.style.background = 'url("https://static.cargo.site/assets/C3/c3label.svg") center center / contain no-repeat';
			div.style.backgroundSize = '60%';
			div.style.transform = 'rotate(-30deg)';

			setTimeout(function(){
				
				div.style.transition = 'opacity 500ms';
				
				setTimeout(function(){
					div.parentNode.removeChild(div);
				}, 700);

				setTimeout(function(){
					div.style.opacity = '0';
				});

			}, 1000);

			document.body.appendChild(div);

		}

		img.src = 'https://static.cargo.site/assets/C3/c3label.svg';

	}

	handleGlobalKeyDown = e => {

		if(
			e.target.nodeName === "INPUT"
			|| e.target.nodeName === "TEXTAREA"
		) {
			// ignore key events when typing in input fields / textareas
			return;
		}

		const state = this.props.store.getState();

		if(!this.props.adminMode) {

			if( ( this.isMac && e.metaKey && e.which === 191 ) || ( !this.isMac && e.ctrlKey && e.which === 191 ) ) {
				
					// cmd + / / cmd + ? to recognize Cargo sites
					this.showCargoSiteIndicator();

					// Only listen to metakey event in special cases so we don't 
					// break native functionality like cmd + r to refresh
					return;
			}

			if( ( this.isMac && e.metaKey && e.which === 27 ) || ( !this.isMac && e.ctrlKey && e.which === 27 ) ) {
				
					// cmd + esc
					this.openAdmin();
					
					// Only listen to metakey event in special cases so we don't 
					// break native functionality like cmd + r to refresh
					return;
			}

			// shift + a / shift + e
			if(
				e.shiftKey 
				&& (e.keyCode === 65 || e.keyCode === 69) 
				&& !state.frontendState.contactForm.inited 
				&& !state.frontendState.cartOpen
				&& !state.frontendState?.quickView?.inited 

			){

				if (this.props.inAdminFrame) {

					// make sure viewers aren't escaping preview
					if(this.props.editorRole !== "Viewer") {

						if(this.props.activePID && this.props.activePID.length > 0 && this.props.activePID !== 'root') {
							parent.navigateAdmin('/' + this.props.activePID);
						} else {
							parent.navigateAdmin('/');
						}

					}

				} else {
					this.openAdmin();
				}
			}
			// left, right, r
			if(
				e.keyCode === 82
				&& !state.frontendState.contactForm.inited
				&& !state.frontendState.cartOpen
				&& !state.frontendState?.quickView?.inited
				&& !e.metaKey
				&& !e.ctrlKey
			) {
				e.preventDefault();
				this.loadRandomPage();
			} else if(
				// arrow left
				e.keyCode === 37
				&& !state.frontendState.contactForm.inited
				&& !state.frontendState.cartOpen
				&& !state.frontendState?.quickView?.inited 
				&& !e.metaKey
				&& !e.ctrlKey
			) {
				e.preventDefault();
				this.loadPrevPage();
			} else if(
				// arrow left
				e.keyCode === 39
				&& !state.frontendState.contactForm.inited
				&& !state.frontendState.cartOpen
				&& !state.frontendState?.quickView?.inited 
				&& !e.metaKey
				&& !e.ctrlKey
			) {
				e.preventDefault();
				this.loadNextPage();
			}


			// esc
			if (
				e.which === 27
				&& !e.metaKey
				&& !e.ctrlKey
			) {

				// If we have a contact form close it and don't navigate.
				if( state.frontendState?.contactForm?.inited ){
					this.props.updateFrontendState({ contactForm: {
						transition: true,
						inited: true,
					}});
					setTimeout(()=>{
						this.props.updateFrontendState({ contactForm: {
							transition: false,
							inited: false,
						}});
					}, 300)
					
					return
				}

				// Ignore navigation attempt if we have
				// Quick view, Cart, Contact Form, Alert
				if( 
					state.frontendState.quickView.inited 
					|| state.frontendState.cartOpen
					|| state.frontendState?.contactForm?.inited
					|| state.frontendState.alertModal
				){
					return
				}

				// only run this outside of admin. Esc closes preview
				if(!this.props.inAdminFrame) {

					const overlays = overlayMethods.getAllOverlays();

					if(overlays.length > 0) {

						// close top overlay
						overlayMethods.closeOverlay(overlays[overlays.length - 1].pid);

					} else if(this.props.history.location.pathname !== '/') {

						// go to the homepage on esc
						this.props.history.push('/');

					}

				}


			}
		}

	}


	componentDidMount() {
		if(!helpers.isServer) {
			window.addEventListener("click", this.handleGlobalClick);
			window.addEventListener("keydown", this.handleGlobalKeyDown);
		}
	}

	componentWillUnmount() {

		if(!helpers.isServer) {
			window.removeEventListener("click", this.handleGlobalClick);
			window.removeEventListener("keydown", this.handleGlobalKeyDown);
		}
	}


	render(){
		return ( this.props.children  );
	}

	loadRandomPage = () => {

		const state = this.props.store.getState();
		let candidates = [];

		_.each(state.sets.byId, set => {

			if(set.stack && set.id !== 'root') {

				// only add if stack has children
				if(set.page_count > 0 && state.frontendState.activePID !== set.id) {
					// add stack to the candidates, don't add any of it's children
					candidates.push([set.id]);
				}

			} else {

				// create an array with all indexes for this set
				const setCandidates = Array.apply(null, {length: set.page_count}).map(Number.call, n => [set.id, n]);
				const setContentsByIndex = selectors.getSetContentsByIndex(state, set.id);

				// add these set's candidates to the list
				candidates = candidates.concat(
					// filter out the deleted array indexes
					setCandidates.filter(([setId, index]) => {

						const contentAtIndex = setContentsByIndex[index];

						if(contentAtIndex) {

							if(
								contentAtIndex.id === state.frontendState.activePID
								|| contentAtIndex.id === state.site.homepage_id
								|| contentAtIndex.id === state.site.mobile_homepage_id
								|| contentAtIndex.page_type === "set"
								|| contentAtIndex.pin === true
							) { 
								return false
							}

						}

						return true;
						
					})
				);


			}

		})

		if(candidates.length === 0) {
			return;
		}

		const result = candidates[Math.floor(Math.random()*candidates.length)];
		const winningSet = state.sets.byId[result[0]]

		if(result.length === 2) {

			this.props.fetchContent(winningSet.id, {
				indexes: [result[1]],
				idType: 'pid'
			}).then(result => {

				if(result.data && result.data[0]) {
					this.props.history.push('/' + result.data[0].purl);
				} else {
					// try again
					this.loadRandomPage();
				}

			});

		} else if(result.length === 1) {			
			
			this.props.history.push('/' + winningSet.purl);

		}

	}

	loadNextPage = () => {

		const state = this.props.store.getState();
		const content = state.pages.byId[this.props.activePID] || state.sets.byId[this.props.activePID];
		
		if(!content || (content.page_type === "set" && !content.stack)) {
			return;
		}

		let nextPageIndex = state.structure.indexById[content.id];

		// content that has no index require knowledge
		// of the entire sort tree to find the next indexable
		// neighbor. Run this special routine:
		if(nextPageIndex === null) {

			if(state.adminState?.crdt.initializedAdminList) {
				// if we have the full site content data loaded in the admin
				// we can retrieve the next neighbor from that draft data alone
				this.loadNearestContentNeighbor(content, 'next');
			} else {
				// otherwise we'll have to use backend to get the next neighbor
				this.props.fetchContent(content.id, {
					next: true
				}).then(result => {

					if(result?.data && result.data[0]) {
						this.props.history.push('/' + result.data[0].purl);
					}

				});
			}

			return;
			
		}

		const parentSet = state.sets.byId[_.findKey(state.structure.byParent, childArray => {
			return childArray.includes(content.id)
		})];

		if(!parentSet || parentSet.page_count === undefined || parentSet.page_count <= 1) {
			return;
		}

		const excludedIndexes = _.without(state.structure.byParent[parentSet.id]?.map(id => state.pages.byId[id] || state.sets.byId[id]).map(function(content){

			if(!content) {
				return;
			}

			if(
				// exclude sets that are not stacked or empty
				(content.page_type === 'set' && (content.stack === false || content.page_count === 0))
				// exclude homepage
				|| content.id === state.site.homepage_id
				|| content.id === state.site.mobile_homepage_id
			) {
				return state.structure.indexById[content.id];
			}

		}), undefined);

		if(excludedIndexes.length === parentSet.page_count) {
			// all pages are excluded. Nothing to do here.
			return;
		}

		// loop over the entire set (wrap around if needed)
		for(let i = 0; i < parentSet.page_count; i++) {

			nextPageIndex++;

			if(nextPageIndex >= parentSet.page_count) {
				nextPageIndex = 0;
			}

			// next item is not excluded, use it
			if(!excludedIndexes.includes(nextPageIndex)) {
				break;
			}

		}

		// grab a list of indexes for this set we have in memory
		const loadedIndexesForSet = state.structure.byParent[parentSet.id]?.reduce((acc, id) => {
			
			if(state.structure.indexById[id] !== null) {
				acc[state.structure.indexById[id]] = id;
			}

			return acc;

		}, {});

		const nextPID = loadedIndexesForSet[nextPageIndex];

		// if we already have the index loaded, go navigate instantly
		if(nextPID) {
			
			// load from memory
			this.props.history.push('/' + (state.pages.byId[nextPID] || state.sets.byId[nextPID])?.purl);

		} else {

			// otherwise fetch it, then try again
			this.props.fetchContent(parentSet.id, {
				indexes: [nextPageIndex],
				idType: 'pid'
			}).then(result => {

				// try again after loading the next page
				this.loadNextPage();

			});

		}

	}

	loadPrevPage = () => {

		const state = this.props.store.getState();
		const content = state.pages.byId[this.props.activePID] || state.sets.byId[this.props.activePID];

		if(!content || (content.page_type === "set" && !content.stack)) {
			return;
		}

		let prevPageIndex = state.structure.indexById[content.id];

		// content that has no index require knowledge
		// of the entire sort tree to find the next indexable
		// neighbor. Run this special routine:
		if(prevPageIndex === null) {

			if(state.adminState?.crdt.initializedAdminList) {
				
				// if we have the full site content data loaded in the admin
				// we can retrieve the prev neighbor from that draft data alone
				this.loadNearestContentNeighbor(content, 'prev');

			} else {
				// otherwise we'll have to use backend to get the prev neighbor
				this.props.fetchContent(content.id, {
					prev: true
				}).then(result => {

					if(result.data && result.data[0]) {
						this.props.history.push('/' + result.data[0].purl);
					}

				});
			}

			return;

		}

		const parentSet = state.sets.byId[_.findKey(state.structure.byParent, childArray => {
			return childArray.includes(content.id)
		})];

		if(!parentSet || parentSet.page_count === undefined || parentSet.page_count <= 1) {
			return;
		}

		const excludedIndexes = _.without(state.structure.byParent[parentSet.id]?.map(id => state.pages.byId[id] || state.sets.byId[id]).map(function(content){

			if(!content) {
				return;
			}

			if(
				// exclude sets that are not stacked or empty
				(content.page_type === 'set' && (content.stack === false || content.page_count === 0))
				// exclude homepage
				|| content.id === state.site.homepage_id
				|| content.id === state.site.mobile_homepage_id
			) {
				return state.structure.indexById[content.id];
			}

		}), undefined);

		if(excludedIndexes.length === parentSet.page_count) {
			// all pages are excluded. Nothing to do here.
			return;
		}
	
		// loop over the entire set (wrap around if needed)
		for(let i = 0; i <= parentSet.page_count; i++) {

			prevPageIndex--;

			if(prevPageIndex < 0) {
				prevPageIndex = parentSet.page_count - 1;
			}

			// next item is not excluded, use it
			if(!excludedIndexes.includes(prevPageIndex)) {
				break;
			}

		}

		// grab a list of indexes for this set we have in memory
		const loadedIndexesForSet = state.structure.byParent[parentSet.id]?.reduce((acc, id) => {
			acc[state.structure.indexById[id]] = id;
			return acc;
		}, {});

		const prevPID = loadedIndexesForSet[prevPageIndex];

		// if we already have the index loaded, go navigate instantly
		if(prevPID) {
			
			// load from memory
			this.props.history.push('/' + (state.pages.byId[prevPID] || state.sets.byId[prevPID])?.purl);

		} else {

			// otherwise fetch it, then try again
			this.props.fetchContent(parentSet.id, {
				indexes: [prevPageIndex],
				idType: 'pid'
			}).then(result => {

				// try again after loading the next page
				this.loadPrevPage();

			});

		}
	}

	loadNearestContentNeighbor = (content, direction) => {

		const state = this.props.store.getState();

		if(direction !== 'next' && direction !== 'prev') {
			throw 'direction needs to be "next" or "prev"'
		}

		const sortedSiteContents = Object.keys(state.structure.bySort).sort((a,b) => {
			return state.structure.bySort[a] - state.structure.bySort[b]
		});

		if(direction === 'prev') {
			sortedSiteContents.reverse();
		}

		// Find the current page in the sort list
		const startIndex = sortedSiteContents.indexOf(content.id);

		if(startIndex === -1) {
			throw('Unable to locate current id in sort list');
		}

		let nextContentId;

		// loop the entire length of the array, starting at startIndex + 1
		for (let i = 1; i < sortedSiteContents.length + 1; i++) {
			// use modulo to wrap around when reaching the end
			const id = sortedSiteContents[(startIndex + i) % sortedSiteContents.length];
			const isSet = state.structure.byParent.hasOwnProperty(id);

			if(isSet && state.sets.byId[id]?.stack === true) {
				// sets only are eligible if they're stacked
				nextContentId = id;
				break;
			} else if(!isSet && state.structure.indexById[id] !== null && id !== state.site.homepage_id && id !== state.site.mobile_homepage_id) {
				// if we're not a set (so a page) and the page has an index and it's not the homepage, it's our guy
				nextContentId = id;
				break;
			}

		}

		const nextContentModel = state.pages.byId[nextContentId] || state.sets.byId[nextContentId];

		if(nextContentModel) {
			// immediately go
			this.props.history.push('/' + nextContentModel.purl);
		} else {
			// fetch it, then navigate
			this.props.fetchContent(nextContentId, {
				idType: 'pid'
			}).then(result => {

				if(result.data && result.data[0]) {
					this.props.history.push('/' + result.data[0].purl);
				}

			});
		}

	}

};


export default withStore(withRouter(
	connect(
		(state, ownProps) => {

			let editorRole;

			if(state.auth.data?.id) {
				editorRole = _.find(state.site?.editors, editor => editor.id === parseInt(state.auth.data.id))?.role
			}

			return {
				editorRole: editorRole,
				adminMode: state.frontendState.adminMode,
				inAdminFrame: state.frontendState.inAdminFrame,
				activePID: state.frontendState.activePID,
				isInHomepagePreview: state.auth.previewing,
				activePURL: (state.pages.byId[state.frontendState.activePID] || state.sets.byId[state.frontendState.activePID])?.purl
			}
		},
		dispatch => bindActionCreators({ 
			updateFrontendState: actions.updateFrontendState,
			fetchContent: actions.fetchContent,
			addCommerceProductToCart: actions.addCommerceProductToCart
		}, dispatch)
	)(
		GlobalEventsHandler
	)
));
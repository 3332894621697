import { Component} from "preact";
import _ from 'lodash';
import { ADMIN_FRAME } from "../../globals";

import GalleryEditor from "./gallery-editor"

class ColumnsEditor extends Component {
	constructor(props){
		super(props);
	}

	render(props, state){
		const {
			galleryInstance,
			gutterWidth,
			elWidth,
			elHeight,
			disabledAttributes
		} = props;

		return <GalleryEditor
				{...props}
				dragIndicationType="columns"
				gallerySpecificAttributes={this.props.gallerySpecificAttributes}
				galleryInstance={galleryInstance}
				mediaItemEditorInfo={{
					disableResize: this.props.isMobile && (this.props['mobile-columns'] !== undefined && this.props['mobile-columns'] !== this.props.columns ) ,
					disableButton: false,
					afterScaleChange: this.afterScaleChange,
					beforeScaleChange: this.spanResize,
					whileScaleChange: this.spanResize,
				}}
				disabledAttributes={disabledAttributes}
			/>		
	}


	spanResize = (mediaItem, resizeDimensions)=>{
		const spans = this.convertWidthToSpans(resizeDimensions)

		return {
			...resizeDimensions,
			displayScale: spans > 1 ? spans + ' columns' : spans + ' column'
		}
	}

	afterScaleChange = (mediaItem, resizeDimensions)=>{
		const spans = this.convertWidthToSpans(resizeDimensions)
		if( spans === 1){
			mediaItem.removeAttribute('columnized-span');
		} else {
			mediaItem.setAttribute('columnized-span', spans)			
		}
		
		this.props.galleryComponent.onItemResize();

	}

	convertWidthToSpans = (resizeDimensions)=>{
		const {columnAndGutterMap}= this.props;

		let spans = columnAndGutterMap.findIndex((columnEdge, index)=>{
			const nextLeftEdge = columnAndGutterMap[index+1]?.left || 9e9;
			const colWidth = columnEdge.right - columnEdge.left;
			return resizeDimensions.width >= columnEdge.left + colWidth*.5 && resizeDimensions.width <= nextLeftEdge+ colWidth*.5;
		})+1

		// minimum of one span
		return Math.max(1, spans);		
	}

}

export default ColumnsEditor;
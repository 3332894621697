import React from 'react';

class ColorFilter extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="colorfilter-color" />
                <div className="colorfilter-base" />
            </>
        )
    }
}

export default ColorFilter;
import { Component, createRef } from "preact";
import { createPortal } from 'preact/compat';
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller";
import { ADMIN_FRAME } from "../../globals";
import { FRONTEND_DATA } from "../../globals";

class EditPageButton extends Component {
	
	constructor(props){
		super(props);
		this.state={
			hover: false,
			buttonHovered: false,
		}
		this.editPageButtonRef = createRef();
		this.layoutButtonRef = createRef();

		if( CargoEditor && Object.keys(CargoEditor || {}).length > 0 ){
			this.bindEditorEvents();
		} else {
			window.addEventListener('CargoEditor-load', this.bindEditorEvents);
		}
	}

	editorIsEmpty = (editor,summary)=>{
		requestAnimationFrame(() => {
			this.setState({
				showEmpty: this.props.isEditingPage
			});
		})
		// let isEmpty = editor.checkIfEmpty();
		// let isFocused = editor.getElement()?.contains(activeRange?.commonAncestorContainer);
	}

	editorHasContent = (editor,summary)=>{
		this.setState({
			showEmpty: false
		})
		// let isEmpty = editor.checkIfEmpty();
		// let isFocused = editor.getElement()?.contains(activeRange?.commonAncestorContainer);
	}

	bindEditorEvents =()=>{
		window.removeEventListener('CargoEditor-load', this.bindEditorEvents);
		this.CargoEditor = CargoEditor;
		this.CargoEditor.events.on('editor-no-content', this.editorIsEmpty);	
		this.CargoEditor.events.on('editor-has-content', this.editorHasContent);		
	}	

	componentWillUnmount=()=>{
		if( this.CargoEditor){
			this.CargoEditor.events.off('editor-no-content', this.editorIsEmpty);
			this.CargoEditor.events.off('editor-has-content', this.editorHasContent);					
		}
	}

	onButtonEnter=()=>{
		this.setState({
			hover: true
		})
	}

	onButtonLeave=()=>{
		this.setState({
			hover: false
		})
	}

	launchUIWindow = (e)=>{
		
		if( e){

			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const svgRect = this.layoutButtonRef.current.getBoundingClientRect();
		// const colRect = this.props.columnInstance.getBoundingClientRect();
		const svgPosition = {
			x: svgRect.x + 0,
			y: svgRect.y + -212,
			left: svgRect.left + 0,
			right: svgRect.right + 0,
			top: svgRect.top + 0 + -212,
			bottom: svgRect.top + 27 + -212,
			width: svgRect.width,
			height: svgRect.height
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'layouttemplate',
			supportsMobile: true,
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: false,
		});	
	}

	render(props){

		const isEditingPage = this.props.isEditingPage;
		const canShowOutlines = this.props.pageIsPin;
		const isEmpty = this.state.showEmpty;
		const buttonHeight = 23;
		
		return <EditorOverlay
			isEditingPage={isEditingPage}
			name="EditPageButton" 
			trackResize={true}
			noPointerEvents={true}
			buttonHeight={buttonHeight}
			buttonMode="outside"
			baseNode={this.props.bodycopyRef.current}
			render={(overlayProps)=>{
				const {
					pointerAttention,
					overlayPosition,
					shiftButtonX,
					shifted,
				} = overlayProps;

				// default position is just above the overlay borders
				let dip = -buttonHeight;
				let xDip = -2;

				// if there isn't enough room for the button
				if( overlayPosition.y <= buttonHeight ){

					// let the button get shifted down into the overlay
					dip = Math.max(0, -overlayPosition.y);
					xDip = 0;

					if( dip > overlayPosition.height ){
						xDip = -2
						dip = overlayPosition.height+1;
					}
				}

				if( isEditingPage && isEmpty){
					return null;
				}

				if( isEditingPage){
					return null;
				}

			    return (<>
					<div 
	    		    	className={`in-editor-buttons page${pointerAttention ? ' hover button-hover': ''}`} 
	    		    	style={{
	    		    		transform: `translate3d(${xDip+overlayPosition.x}px, ${dip+overlayPosition.y}px, 0)`
	    		    	}}
	    	    	>
		    	    	<div
		    	    		className={`pointer-mask ${shifted ? 'shifted' : ''}`}
	    			    	style={{
	    			    		transform: `translateX(${shiftButtonX}px)`,
	    			    	}}			    	    		
		    	    	>
		    			    <button

		    					className="edit-page text"
		    					onMouseDown={(e)=>{
		    						if( e.button == 2 || e.metaKey){
		    							return;
		    						}
		    						this.props.editPage(e) 
		    					}}
		    					ref={this.editPageButtonRef}
		    				>
		    					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		    						<path fillRule="evenodd" clipRule="evenodd" d="M15 16H6V4H10.9875V8.09705H15V16ZM16 7.64491L11.4226 3H5V17H16V7.64491Z" fill="black"/>
		    					</svg>
		    					{this.props.pageTitle}
		    				</button>
		    			</div>
	    			</div>			    	
		    	    <div
		    	    	className={`editor-overlay editor-outline page-outline${pointerAttention ? ' hover button-hover': ''}${this.state.showEmpty ? ' hover': ''}`}
		    	    	style={{
		    				width: overlayPosition.width+'px',
		    				height: overlayPosition.height+'px',
		    				transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
		    	    	}}			    	
		    	    >
		    	    </div>
	    	    </>)			   

		}}/>

	}


}

export default EditPageButton;


import { Component , cloneElement, toChildArray, render, Fragment, createRef} from "preact";
import { PureComponent, createPortal } from 'preact/compat';
import { connect } from 'react-redux';
import { CRDTState } from "../../../globals";
import * as helpers from "@cargo/common/helpers";
import { API_ORIGIN } from "@cargo/common/environment";
import axios from 'axios';
import { withPageInfo } from "../../page/page-info-context";
import { memoizeWeak } from "../../../helpers";
import selectors from "./../../../selectors";
import getSlug from "@cargo/slug-generator";

import _ from 'lodash';

function getFilterConfig(rawConfig) {

	const configParts = rawConfig.split(':');
	const type = configParts[0];

	let value = 'all';
	switch(type){
		case "set":
			value = _.tail(configParts).map(part=>part).join(':');
			break;

		case "tag":
			value =  _.tail(configParts)[0]?.split(',').map(part=>getSlug(part.trim()));
			break;

		case "img":
			value = 'all';
			break;

		case "all":
			value = 'all';
			break;

	}

	return {
		type,
		value
	}

}

const getThumbs = memoizeWeak((config, structure, pages) => {

	let thumbCollection = [];

	if(!config) {
		return thumbCollection;
	}

	const thumbFilter = getFilterConfig(config);

	switch(thumbFilter.type) {
		case 'set':

			thumbCollection = (structure.byParent[thumbFilter.value] || []).map(id => {
				return pages.byId[id]
			}).filter(model => model !== undefined);

			break;
		case 'tag':
			thumbCollection = Object.values(pages.byId).filter(page => {
				return page.tags && page.tags.find(tag => thumbFilter.value?.includes(tag.url) )
			});

			break;
		case 'img':

			thumbCollection = [];
			break;

		default: 

			// no filter, return all
			thumbCollection = Object.values(pages.byId)

			break;
	}

	// Generate thumb data from raw page collection
	return thumbCollection.map(page => {

		let thumbnail = null;

		// password-protected pages do not allow access to page.media
		if(page.thumb_media_id && page.media)  {
			thumbnail = page.media.find(media => media.id === page.thumb_media_id) || null;
		} else if( page.thumbnail ){
			thumbnail = page.thumbnail
		} 

		return {
			id: page.id,
			title: page.title,
			purl: page.purl,
			thumb_meta: page.thumb_meta || {},
			display: page.display,
			sort: structure.bySort[page.id],
			tags: page.tags || [],
			pin: page.pin,
			crdt_state: page.crdt_state,
			thumbnail
		}

	});

})

function withThumbnailContent(WrappedComponent){

	class RenderThumbs extends PureComponent {

		constructor(props) {

			super(props);

			this.fixBadNullValue();

			this.state = {
				layoutIncrement: 0,
				initialLoad: false,
				attributeMap: this.getMapFromAttribute(),
				thumbnails: [],
				renderLimit: 150,
			}
 			
		}

		getMapFromAttribute = ()=>{

			let attributeMap ={};

			try {
				let attributeMapString = unescape(this.props['thumbnail-index-metadata'] || '{}');
				attributeMap = JSON.parse(attributeMapString);
			} catch (error) {
				attributeMap = {};
				console.error('failed to load saved thumbnail map: ', error)
			}

			return attributeMap;
		}

		fixBadNullValue = ()=>{
			// fix for bad attribute being added to galleries in creation - step
			if( this.props['thumbnail-index'] === 'null' && this.props.baseNode){
				this.props.baseNode.removeAttribute('thumbnail-index')
			}

			if( this.props.baseNode && this.props.baseNode.hasAttribute('tag') ){
				this.props.baseNode.removeAttribute('tag')
			}
		}


		isActiveLink = (model) => {


			// If editing in the admin, do not set active links, or they will be stored in CRDT
			// if (this.props.PIDBeingEdited === this.props.pageInfo?.pid) return false;

			// let editingInAdmin = this.props.inAdminFrame && this.props.adminMode === true;

			if (//!editingInAdmin && 
					( model.purl === this.props.activePURL ) // if it's the active PURL
					|| ( model.id === this.props.PIDBeingEdited ) // if it's the active PURL
			) {
				return true;

			} else {
				return false;
			}
			
		}

		render(props){
			if(!props['thumbnail-index'] || props['thumbnail-index'] === 'null'){
				const wrappedProps = {
					...props,
					'thumbnail-index': undefined
				}
				return <WrappedComponent {...wrappedProps} />
			}


			let hasCollection = this.state.thumbnails.length > 0;
			let showTags = hasCollection && props['show-tags'];
			let showTitles = hasCollection && props['show-title'];

			let thumbCollection = hasCollection || !this.state.initialLoad ? this.state.thumbnails : this.props.adminMode ? [null,null,null].fill(
				{
					attributes: {
						href: null,
						hash: 'placeholder',
					},
					purl: null,
					thumbnail: null,
					tags: [],
					title: null
				}) : [];
			
			// only allow custom sort in freeform galleries
			if( this.props.baseNode.tagName ==='GALLERY-FREEFORM'){
				thumbCollection = _.sortBy(thumbCollection, (thumb)=>{
					const attr = this.state.attributeMap?.[thumb.id];
					if( attr != undefined){
						return attr.sort+1
					}
					return 0;
				});				
			} 

			thumbCollection = thumbCollection.slice(0, this.state.renderLimit);
			
			this.renderedItemCount = thumbCollection.length;

			return <WrappedComponent {...props} onCleanUp={this.onCleanUp} disableDragOver={true} >
				{thumbCollection.map((model, itemIndex)=>{
					const loopingVideo = model.thumbnail?.is_video || model.thumbnail?.is_url || null;
					return <media-item
						{...this.state.attributeMap[model.id]?.attributes }
						saveable={false}
						model={model.thumbnail}
						autoplay={loopingVideo}
						loop={loopingVideo}
						muted={loopingVideo}
						href={model.purl}
						rel="history"
						itemDragStart={this.onDragStart}
						key={'thumb id'+model.id}
						disable-zoom={true}
						className={`thumbnail linked ${model.tags.map(tagObj=> { if ( /^\d/.test(tagObj.url) ){ return '-'+tagObj.url} return tagObj.url  }).join(' ').toLowerCase()}${this.isActiveLink(model) ? ' active' : ''}`}
						{...model.attributes}						
					>
						<figcaption class={`caption${!showTitles && !showTags ? ' empty': '' }`} slot="caption">
							{showTitles ? <div className="page-title" key={'title-'+model.url+'-'+model.title+'-'+this.state.layoutIncrement} >{model.title}</div> : null }
							{model.tags.length > 0 && showTags ?
								<div className="tags">{model.tags.map((tagObj, index)=>{
									return <Fragment key={'tag-link-'+tagObj.url+'-'+this.state.layoutIncrement}>
										<a
											href={tagObj.url}
											className={`tag-link`}
											rel={props['links-filter-index'] ? 'filter-index' : 'history'}
											data-tag={tagObj.tag}

										>{tagObj.tag+''}</a>
										{index < model.tags.length-1 ? <span className="tag-separator"></span> : null}
									</Fragment>
								})}</div> : null
							}
						</figcaption>
					</media-item>
				})}
			</WrappedComponent>
		}


		onDragStart=(e)=>{
			if( !this.props['thumbnail-index'] || this.props['thumbnail-index'] === 'null' ){
				return
			}

			// only allow dragging inside freeform thumb gallery 
			if(
				this.props.adminMode && this.props.baseNode.tagName !== 'GALLERY-FREEFORM'
			
			){
				e.preventDefault();
				e.stopPropagation();
			}
		}

		componentDidUpdate(prevProps, prevState){

			this.fixBadNullValue();

			
			if(
				// if we have a thumbnail value...
				this.props['thumbnail-index']
				&& (
					// and it changes
					prevProps['thumbnail-index'] !== this.props['thumbnail-index']

					// or the collection of content changes
					|| this.props.thumbCollection !== prevProps.thumbCollection

					// or the sort changes
					|| this.props.sort !== prevProps.sort

					// or if the publish timestamp is updated - we need to get a fresh api result once the crdt clears
					|| this.props.lastPublishedAt !== prevProps.lastPublishedAt
				)
			) {
				this.setThumbnails();
			}



			if( this.props['thumbnail-index-metadata'] !== prevProps['thumbnail-index-metadata']){
				this.setState({
					attributeMap: this.getMapFromAttribute()
				})
			}
		
			if(!this.ticking) {

				this.ticking = true;
				
				setTimeout(() => {

					if(this.renderedItemCount >= this.state.renderLimit) {
						this.setState({
							renderLimit: this.state.renderLimit + 250
						})
					}

					delete this.ticking;

				}, 200);

			}
			

			if( this.props['thumbnail-index'] !== prevProps['thumbnail-index'] && this.props['thumbnail-index'] && this.props['thumbnail-index'] !== 'null' ){
				this.props.pageInfo.setPageData({filter: this.props['thumbnail-index']}, this.props.baseNode);
				this.emitFilterEvent();
			}

		}

		emitFilterEvent = ()=>{

			if( this.props['thumbnail-index'] && !this.props.adminMode){
				this.emitFilterDebounce = requestAnimationFrame(()=>{
					var event = new CustomEvent('filter-thumbnail-index', {
						bubbles: true,
						cancelable: true,
						composed: true,
						detail: {
							filter: this.props['thumbnail-index']
						}
					})
					this.props.baseNode.dispatchEvent(event);
				})
			}
		}

		updateOldThumbMetaData =_.debounce(()=>{
			const scriptTag = this.props.baseNode.querySelector('script[type="text/thumbnail-metadata"]');
			if( scriptTag){
					
				try {

					CargoEditor?.mutationManager?.execute?.(()=>{
						this.props.baseNode.setAttribute('thumbnail-index-metadata', escape(scriptTag.textContent));
					}, {
						// this change should not be able to force a page into draft mode
						preventDraft: true
					});

				} catch(e) {
					//console.error(e);
				}

				CargoEditor?.mutationManager?.execute?.(()=>{
					scriptTag.remove();
				}, {
					// this change should not be able to force a page into draft mode
					preventDraft: true
				});

			}
		}, 2000)

		componentDidMount(){

			this.fixBadNullValue();
			this.updateOldThumbMetaData();

			this.setThumbnails();
			
			if( this.props['thumbnail-index'] && this.props['thumbnail-index'] !== 'null' ){

				this.emitFilterEvent();

				// var mediaItems = Array.from(this.props.baseNode.querySelectorAll('media-item'));
				// mediaItems.forEach((el)=>{
				// 	el.setSaveable(false)
				// })
			}
			this.props.baseNode.addEventListener('keydown', this.onKeyDown)
			this.props.baseNode.addEventListener('filter-thumbnail-index', this.setThumbnails)	
			document.addEventListener('filter-thumbnail-index', this.setThumbnails)	

			this.props.baseNode.addEventListener('refresh-thumbnail-index', this.setThumbnails)	

		}

		componentWillUnmount(){
			cancelAnimationFrame(this.emitFilterDebounce);
			this.props.baseNode.removeEventListener('refresh-thumbnail-index', this.setThumbnails)	

			this.props.baseNode.removeEventListener('keydown', this.onKeyDown)
			this.props.baseNode.removeEventListener('filter-thumbnail-index', this.setThumbnails)
			document.removeEventListener('filter-thumbnail-index', this.setThumbnails)
		}

		onKeyDown = (e)=>{

			if ( e.metaKey || e.ctrlKey || !this.props['thumbnail-index']){
				return;
			}

			if(  /^.$/u.test(e.key) || e.key ==='Enter' || e.key ==='Delete' || e.key ==='Backspace' ){
				e.preventDefault();
				e.stopPropagation();
			}

		}

		setThumbnails = async () => {

			if(!this.props['thumbnail-index']) {
				return;
			}

			const thumbFilter = getFilterConfig(this.props['thumbnail-index']);
			const timestamp = this.props.lastPublishedAt;

			let apiThumbnailData = [];

			switch(thumbFilter.type) {
				case 'set':
					
					try {
						({ data: apiThumbnailData } = await axios.get(`${API_ORIGIN}/pages/${this.props.siteId}/thumbs/set/${thumbFilter.value}?limit=999&timestamp=${timestamp}`, {
							use_cache: true
						}));
					} catch(e) {
						//console.error(e);
					}

					break;
				case 'tag':

					try {
						({ data: apiThumbnailData } = await axios.get(`${API_ORIGIN}/pages/${this.props.siteId}/thumbs/tag/${thumbFilter.value.join(',')}?limit=999&timestamp=${timestamp}`, {
							use_cache: true
						}));
					} catch(e) {
						//console.error(e);
					}

					break;

				case 'img': 

					try {
						({ data: apiThumbnailData } = await axios.get(`${API_ORIGIN}/sites/${this.props.siteId}/media/images?with-pages=true`, {
							use_cache: true
						}));
					} catch(e) {
						//console.error(e);
					}

					apiThumbnailData = apiThumbnailData.filter(page=>{
						return page.media.length > 0 && page.password_enabled == false
					});

					apiThumbnailData = _.flatMap(apiThumbnailData, page=>{
						return page.media.map(item=>{
							return {
								display: true,
								id: item.id,
								purl: page.purl,
								url: page.purl,
								sort: 0,
								tags: [],
								thumbnail: item,
								title: <><a href={page.purl} rel="noopener nofollow" target="_blank" className="page-link">{page.title}</a><br/><a target="_blank" rel="noopener nofollow" href={'https://freight.cargo.site/o/i/'+item.hash+'/'+item.name}>{item.name}</a></>,
								attributes: {
									href: null,
									'force-quick-view': true,
									hash: item.hash,
								}
							}

						});

					});

					break;

				default: 

					// no filter
					try {
						({ data: apiThumbnailData } = await axios.get(`${API_ORIGIN}/pages/${this.props.siteId}/thumbs/all?limit=999`, {
							use_cache: true
						}));
					} catch(e) {
						//console.error(e);
					}

					break;
			}

			const state = store.getState();

			// thumbnails for a pages that don't have the crdt_state of Published should be 
			// removed from the API data as that page is altered since publish
			apiThumbnailData = apiThumbnailData.filter(thumb => {

				const page = state.pages.byId[thumb.id];

				// check if page was changed since publish
				if(page && page.crdt_state !== CRDTState.Published){
					// page was changed, do not use API data
					return false;
				}

				// check if page still belongs to set
				if(
					page
					// if filtering by set
					&& thumbFilter.type === "set" 
					// and we have the structure data for that set
					&& state.structure.byParent[thumbFilter.value]
					// and this page is not part of said set
					&& !state.structure.byParent[thumbFilter.value].some(pid => pid === page.id)
				) {
					// page was moved, do not use API data
					return false;
				}

				// Not changed and not moved, use API data
				return true;

			});

			// overlay draft thumb collection on top of API data
			let thumbnails = _.unionBy(this.props.thumbCollection, apiThumbnailData, 'id').sort((a,b) => a.sort - b.sort);

			const sort = this.props.sort || 'forward';
			switch(sort){

				case "reverse":
					thumbnails.reverse();
					break;
				case "shuffle":

					thumbnails.sort((a) => {
						return Math.random() -.5
					});
					break;
			}

			// filter out pins, homepages, and pages excluded from indexes
			thumbnails = thumbnails.filter(thumb => {

				// pages cannot be pinned
				return thumb.pin !== true 
						// or 'display': false (excluded from index)
						&& thumb.display !== false 
						// or the homepage
						&& thumb.id !== this.props.homepageID 
						// or the mobile homepage
						&& thumb.id !== this.props.mobileHomepageID
						// or flagged for deletion
						&& thumb.crdt_state !== CRDTState.Deleted
						// or thumb meta has 'hide_from_index' set to true
						&& thumb.thumb_meta?.hide_from_index !== true
						// or if thumb is for the page this index is within
						&& this.props.pageInfo.pid !== thumb.id
			})

			this.setState({
				initialLoad: true,
				thumbnails: thumbnails
			})

		}


		onCleanUp = (addedMediaItems,removedMediaItems, changedMediaItems, changedCharacterData)=>{

			if( !this.props['thumbnail-index'] || !this.props.adminMode){
				return
			}


			let attributeMap = {};
			let attributeMapString = '';

			const state = store.getState();

			const mediaItems = Array.from(this.props.baseNode.children).filter(el=>el.tagName==="MEDIA-ITEM");

			mediaItems.forEach((mI, index)=>{
				const href = mI.getAttribute('href');

				let storedAttributes = {};
				let a = mI.attributes;
				let i = 0;

				for (i = a.length; i--; ) {

					if (
						a[i].name === 'saveable' ||
						a[i].name ==='limit-by' ||
						a[i].name ==='class' ||
						a[i].name === 'hash' ||
						a[i].name === 'href' ||
						a[i].name === 'rel' ||
						a[i].name === 'slot' ||
						a[i].name === 'width' ||
						a[i].name === 'height' || 
						a[i].name === 'disable-zoom' 
					) {
						continue;
					}
					
					storedAttributes[a[i].name] = a[i].value;

				}

				const pid = selectors.getContentByPurl(state, href)?.id || null;
				if(pid){
					attributeMap[pid] = {
						sort: index,							
						attributes: storedAttributes,
					}
				}
			});

			attributeMap = {
				...this.state.attributeMap,				
				...attributeMap,
			}

			attributeMapString = JSON.stringify(attributeMap);
			attributeMapString = escape(attributeMapString);

			if( attributeMapString !== this.props['thumbnail-index-metadata'] ){

				CargoEditor?.mutationManager?.execute?.(()=>{
					this.props.baseNode.setAttribute("thumbnail-index-metadata", attributeMapString);
				}, {
					// this change should not be able to force a page into draft mode
					preventDraft: true
				});

				this.setState({
					attributeMap: attributeMap || {}
				});

			} else if ( changedCharacterData ){

				if( CargoEditor && CargoEditor?.getActiveEditor?.() ){
					const range = CargoEditor.rangy.createRange();
					range.selectNode(this.props.baseNode);
					range.collapse();
					CargoEditor.helpers.setActiveRange(range);
				}
				
				this.setState({
					layoutIncrement: this.state.layoutIncrement+1
				})
			}

		}


    }


	return withPageInfo(connect((state, ownProps) => {

		const thumbCollection = getThumbs(ownProps['thumbnail-index'], state.structure, state.pages);

		let activePURL = (state.pages.byId[state.frontendState.activePID] || state.sets.byId[state.frontendState.activePID])?.purl;

		if(!helpers.isServer && !activePURL) {
			activePURL = window.location.pathname.replace(/^\//, '');
		}

		// lowercase the activePURL to avoid case sensitivity
		if(typeof activePURL === "string") {
			activePURL = activePURL.toLowerCase();
		}

		return {
			lastPublishedAt: state.adminState?.crdt?.publishedAt ?? '000',
			thumbCollection,
			activePURL,
			adminMode : state.frontendState.adminMode,
			PIDBeingEdited 	: state.frontendState.PIDBeingEdited,
			inAdminFrame: state.frontendState.inAdminFrame,
			siteId: state.site.id,
			homepageID: state.site.homepage_id,
			mobileHomepageID: state.site.mobile_homepage_id
		}

	})(RenderThumbs));

}

export default withThumbnailContent


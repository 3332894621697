import { Component} from "preact";
import _ from 'lodash';
import { ADMIN_FRAME, FRONTEND_DATA } from "../../globals";
import { editorOverlayAPI } from "./editor-overlay-controller"

import GalleryEditor from "./gallery-editor"

class JustifyEditor extends Component {
	constructor(props){
		super(props);
		this.state = {

		}

	}

	render(props, state){
		const {
			galleryInstance,
			gutterWidth,
			elWidth,
			
			mediaItems,
		} = props;

		return <GalleryEditor
			{...props}
			gallerySpecificAttributes={this.props.gallerySpecificAttributes}		
			onAfterDrop={this.onAfterDrop}
			galleryInstance={galleryInstance}
			disabledMediaItemOptions={['scale']}
			mediaItemEditorInfo={{
				disableResize: true,
				disableButton: false,
				additionalUI: this.renderBracket,
			}}
		>

		</GalleryEditor>
	}

	onBracketClick = (hardBreak, mediaItem, e)=>{

		if( this.dragStarted){
			return;
		}

		if( e ){
			e.preventDefault();	
			editorOverlayAPI.setHoveredElements([this.props.galleryInstance]);			
		}
		

		CargoEditor.mutationManager.execute(()=>{
			// toggle hard break off
			if( hardBreak){
				mediaItem.removeAttribute('justify-row-end');

			// add hard break to element and wherever there is a soft break
			}else {
		
				let prevItem = mediaItem.previousSibling;
				let itemsInRow = 1;
				while (	prevItem ){

					if( prevItem.nodeName ==="MEDIA-ITEM" && prevItem.assignedSlot){

						// last item in row - naturally a bracket
						let softBreak = prevItem.assignedSlot.parentElement.lastChild == prevItem.assignedSlot;
						if( softBreak){
							prevItem.setAttribute('justify-row-end', 'true')		
						}

					} 

					prevItem = prevItem.previousSibling;

				}
				
				mediaItem.setAttribute('justify-row-end', 'true')					

			}
		})
		if( this.props.incrementLayout ){
			this.props.incrementLayout();
		}		

	}

	renderBracket = (mediaItemEditor, mediaItem)=>{

		const {
			gutterWidth, gutterHeight
		} = this.props;

		let slot = mediaItem.assignedSlot;
		if( !slot){
			return null;
		}

		// last item in row - naturally a bracket
		let softBreak = slot.parentElement.lastChild == slot;

		// hard set bracket through justify attribute
		let hardBreak = mediaItem.getAttribute('justify-row-end') === 'true';

		let cursor = 'pointer'
		if ( !hardBreak ){
			cursor = 'e-resize';
		}

		return <div className={`justify-bracket${ hardBreak ? ' row-end': ''}`}
			style={{
				'--media-item-pad': -mediaItem._size.mediaItemSize.padSize*.5+-1+'px',
			}}
			onClick={(e)=>{this.onBracketClick(hardBreak, mediaItem, e)}}
			onMouseDown={(e)=>{this.onMouseDown(mediaItem, e)}}
		>
			
		</div>
	}

	componentWillUnmount(){
		this.setRow.cancel();		
	}

	onMouseDown = (mediaItem, e)=>{
		let slot = mediaItem.assignedSlot
		if( !slot ){
			return;
		}
		const {
			mediaItems
		} = this.props;

		e.preventDefault();

		let row = slot.parentElement;
		let rowItems = Array.from(row.children);

		this.delta = 0;
		this.initialPoint = e.clientX;
		this.rowItems = rowItems;
		this.indexOfFirstInRow = mediaItems.indexOf(this.rowItems[0].assignedNodes()[0])

		this.dragStarted = false;

		window.addEventListener('pointermove', this.onPointerMove);
		window.addEventListener('pointerup', this.onPointerUp);

	}

	onPointerMove= (e)=>{
		const {
			mediaItems
		} = this.props;

		this.delta = e.clientX - this.initialPoint;

		if( Math.abs(this.delta) < 3){
			return;
		}

		if( !this.dragStarted){
			editorOverlayAPI.setHoveredElements([this.props.galleryInstance]);
			editorOverlayAPI.lock();
			document.body.classList.add('dragging');
			const mediaItem = mediaItems[this.indexOfFirstInRow-1];
			if( mediaItem){
				this.onBracketClick(false, mediaItem, null)				
			}
			this.attrCache= mediaItems.map(el=>{
				return el.getAttribute('justify-row-end') === 'true'
			})			

		}

		this.dragStarted =true;
		this.setRow();
	}

	setRow = _.throttle(()=>{

		CargoEditor.mutationManager.execute(()=>{

			const {
				elWidth,
				mediaItems,
				rowAlign,
			} = this.props;

			let increment = elWidth/12;
			let spans = Math.round(this.delta/increment);
			if( rowAlign == 'fill' || rowAlign == "right" ){
				spans = spans*-1;
			}
			let rowLength = Math.max( spans + this.rowItems.length, 1);
				editorOverlayAPI.setHoveredElements([this.props.galleryInstance]);
				editorOverlayAPI.lock();
			this.attrCache.forEach((isRowEnd, index)=>{


				if(
					index >= this.indexOfFirstInRow &&
					index <= this.indexOfFirstInRow + rowLength
				){

					isRowEnd = false;
					if( index == this.attrCache.length-1 || index == this.indexOfFirstInRow+rowLength+-1){
						isRowEnd = true;
					}

				}

				const mediaItem = mediaItems[index];
				const attr = mediaItem.getAttribute('justify-row-end');
				if( isRowEnd && attr !=='true'){
					mediaItem.setAttribute('justify-row-end', isRowEnd)
				} else if ( !isRowEnd && mediaItem.hasAttribute('justify-row-end') ){
					mediaItem.removeAttribute('justify-row-end');
				}
				
			})
		});
		if( this.props.incrementLayout ){
			this.props.incrementLayout();
		}

	}, 80)

	onPointerUp = (e)=>{
		editorOverlayAPI.unlock();
		editorOverlayAPI.testHover();
		document.body.classList.remove('dragging');		
		this.setRow.cancel();
		window.removeEventListener('pointermove', this.onPointerMove);
		window.removeEventListener('pointerup', this.onPointerUp);		
	}


	onAfterDrop = (draggedMediaItems, insertBeforeTarget, adjacentIndexes)=>{

		// let setLeft = false;
		let setRight = false;

		let searchEl = insertBeforeTarget;

		let nextSibling = draggedMediaItems[draggedMediaItems.length-1];
		let prevSibling = draggedMediaItems[0];


		while(nextSibling && draggedMediaItems.indexOf(nextSibling) > -1 ){		
			nextSibling = nextSibling.nextElementSibling
		}
		while(prevSibling && draggedMediaItems.indexOf(prevSibling) > -1 ){		
			prevSibling = prevSibling.previousElementSibling
		}		

		/*
			dragged nodes have been put in the middle of a row
		*/
		if(adjacentIndexes.left > -1 && adjacentIndexes.right > -1){

			if(insertBeforeTarget === draggedMediaItems[draggedMediaItems.length-1]){
				nextSibling = draggedMediaItems[draggedMediaItems.length-1]
			}

			if( insertBeforeTarget === draggedMediaItems[0].nextElementSibling ){
				prevSibling = draggedMediaItems[0]

			}

		/*
			dragged nodes have been put on the left edge of a row
		*/
		} else if (adjacentIndexes.left > -1 && adjacentIndexes.right == -1){
			nextSibling = null;


		/*
			dragged nodes have been put on the right edge of a row
		*/
		} else if ( adjacentIndexes.right > -1 && adjacentIndexes.left == -1){
			prevSibling = null;
		}

		if( prevSibling && adjacentIndexes.left > -1 && (
			prevSibling.getAttribute('justify-row-end') == 'true' ||
			prevSibling.getAttribute('justify-row-end') == true
		)){
			// setRight = true;
			prevSibling.removeAttribute('justify-row-end');

			draggedMediaItems[draggedMediaItems.length-1].setAttribute('justify-row-end', true);
		}

	}


}

export default JustifyEditor;